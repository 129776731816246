import base from "./base";
import axios from "../utils/http";
import qs from 'qs'

const api = {
  getPotential (params) {
    return axios.post(base.baseUrl + base.potential, params);
  },
  getChannel (params) {
    return axios.post(base.baseUrl + base.channel, params);
  },
  saveMessage (params) {
    //   console.log(params)
    //   let sendMsg = qs.stringify(params, { arrayFormat: 'repeat' })
    return axios.post(base.saveMsg, params)
    // let dev = window.location.hostname.split(".")[0];
    // if (dev === "localhost" || dev === "192" || dev === "test" || dev === "172") {
    //   return axios.post(base.saveMsg, params)
    // } else {
    //   alert('马上试用')
    //   return axios.post(base.baseUrl + base.saveMsg, params)
    // }

  },
};
export default api;
