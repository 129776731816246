import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
  path: "/index",
  name: "layout",
  component: () =>
    import ( /* webpackChunkName: "about" */ "../components/Layout.vue"),
  children: [
    {
      path: "/success",
      name: "success",
      component: () =>
        import (
          /* webpackChunkName: "about" */
          "../views/potential/success.vue"
          ),
    },
  ],
},
  {
    path: "/",
    name: "common",
    component: () =>
      import ( /* webpackChunkName: "about" */ "../components/CommonLayout.vue"),
    children: [
      {
        path: "/",
        name: "homePage",
        component: () =>
          import (
            /* webpackChunkName: "about" */
            "../views/homePage/index.vue"
            ),
      },
      {
        path: "/productIntroduction",
        name: "ProductIntroduction",
        component: () =>
          import (
            /* webpackChunkName: "about" */
            "../views/homePage/ProductIntroduction.vue"
            ),
      },
      {
        path: "/solution",
        name: "solution",
        component: () =>
          import (
            "../views/solution/index.vue"
            ),
      },
      {
        path: "/pdSoluting",
        name: "pdSoluting",
        component: () =>
          import (
            "../views/solution/pdSoluting.vue"
            ),
      },
      {
        path: "/zcSolution",
        name: "zcSolution",
        component: () =>
          import (
            "../views/solution/zcSolution.vue"
            ),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () =>
          import (
            "../views/aboutUs/aboutUs.vue"
            ),
      },
      {
        path: "/business",
        name: "business",
        component: () =>
          import (
            "../views/busuness/business.vue"
            ),
      },
      {
        path: "/ruler",
        name: "ruler",
        component: () =>
          import (
            "../views/ruler/index.vue"
            ),
      },
    ],
  },
  {
    path: "/free",
    name: "free",
    component: () =>
      import (
        /* webpackChunkName: "about" */
        "../views/homePage/free.vue"
        ),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
