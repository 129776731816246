import Vue from "vue";
import Vuex from 'vuex'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api/api";
import './assets/style/comStyle.css'
import global from './utils/Global.js'
import './assets/iconfont/iconfont.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.GLOBAL = global
Vue.use(ElementUI);
Vue.use(Vuex)
Vue.use(VueAxios, axios)
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
